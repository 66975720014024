import { render, staticRenderFns } from "./documents.vue?vue&type=template&id=6597f3c3&"
import script from "./documents.vue?vue&type=script&lang=js&"
export * from "./documents.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeader: require('/home/vsts/work/1/s/components/PageHeader.vue').default,AeqFileField: require('/home/vsts/work/1/s/components/AeqFileField.vue').default})
